import PageBanner from "../../components/Common/PageBanner";
import ContactForm from "../../components/Contact/ContactForm";
import ContactInfo from "../../components/Contact/ContactInfo";
import Footer from "../../components/Layouts/Footer";
import Navbar from "../../components/Layouts/Navbar";

export default function ContactUs() {

  return (
    <>
      <Navbar />

      <PageBanner
        pageTitle="Contato"
        breadcrumbTextOne="Início"
        breadcrumbTextTwo="Contato"
        breadcrumbUrl="/"
        bgImage="images/page-title-bg.jpg"
      />

      <ContactInfo />

      <ContactForm />

      <Footer />
    </>
  );
}
