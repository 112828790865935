/* eslint-disable jsx-a11y/img-redundant-alt */

export default function AgentsAI() {
  const assistAI = [
    "Responde dúvidas",
    "Resolve problemas",
    "Orienta usuários",
    "Realiza agendamentos",
    "Envia links e instruções",
    "Envia notificações",
  ];

  const leadAI = [
    "Responde dúvidas",
    "Verifica o interesse",
    "Identifica a necessidade",
    "Qualifica leads",
    "Analisa o perfil",
    "Repassa leads",
  ];

  return (
    <>
      <div id="templates" className="software-section pt-100">
        <div id="assist" className="container">
          <div className="section-title">
            <span>MODELOS IA</span>
            <h2>Conheça nossos agentes</h2>
          </div>
          <div className="assist-content row align-items-center">
            <div className="col-lg-6">
              <div className="software-image">
                <img
                  src="/images/ai/conversation1.png"
                  alt="image"
                  width={555}
                  height={356}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="software-content">
                <h2>Assistente IA</h2>
                <p>
                  <strong>Assistente IA</strong> é um agente especializado em
                  atendimento a clientes e usuários. Ele pode automatizar
                  tarefas rotineiras através de comandos enviados via chat ou
                  voz.
                </p>

                <div>
                  <ul className="features-list">
                    {assistAI.map((service, index) => (
                      <li key={index}>
                        <span className="text-center">{service}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="lead" className="container">
          <div className="lead-content row align-items-center">
            <div className="col-lg-6">
              <div className="software-content">
                <h2>Lead IA</h2>
                <p>
                  <strong>Lead AI</strong> é um agente especializado em
                  qualificação de leads. Ele trabalha na área de pré-vendas,
                  prospectando e qualificando leads para a empresa de forma
                  eficiente e automatizada.
                </p>
                <div className="mt-2">
                  <ul className="features-list">
                    {leadAI.map((item, index) => (
                      <li key={index}>
                        <span className="text-center">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="software-image">
                <img
                  src="/images/ai/conversation2.png"
                  alt="image"
                  width={555}
                  height={356}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
