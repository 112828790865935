import MainBanner from "../../components/Home/MainBanner";
import Beneficts from "../../components/AI/Beneficts";
import HowToUse from "../../components/AI/HowToUse";
import Footer from "../../components/Layouts/Footer";
import Navbar from "../../components/Layouts/Navbar";
import AgentsAI from "../../components/AI/AgentsAI";
import WhatIsAI from "../../components/AI/WhatIsAI";

export default function Home() {
  return (
    <>
      <Navbar />

      <MainBanner />

      <Beneficts />

      <WhatIsAI />

      <AgentsAI />

      <HowToUse />

      <Footer />
    </>
  );
}
