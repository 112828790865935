import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./pages/styles/bootstrap.min.css";
import "./pages/styles/flaticon.css";
import "./pages/styles/fontawesome.min.css";
import "react-tabs/style/react-tabs.css";
import "swiper/css";
import "swiper/css/bundle";

import "./pages/styles/style.css";
import "./pages/styles/responsive.css";

import "./globals.css";
import Home from "./pages/home/home";
import GoTop from "./components/Layouts/GoTop";
import AosAnimation from "./components/Layouts/AosAnimation";
import ContactUs from "./pages/contact-us/contact-us";
import WhatsAppButton from "./components/Layouts/WhatsAppButton";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
      <AosAnimation />
      <WhatsAppButton />
      <GoTop />
    </Router>
  );
}

export default App;
