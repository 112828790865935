export default function MainBanner() {
  return (
    <>
      <div id="home" className="main-banner-two">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div  className="main-banner-content">
                    <h1
                      data-aos="fade-up"
                      data-aos-delay="100"
                      data-aos-duration="1200"
                    >
                      Impulsione seu negócio com assistentes de IA que nunca
                      descansam
                    </h1>

                    <div
                      data-aos="fade-up"
                      data-aos-delay="200"
                      data-aos-duration="1200"
                    >
                      <p>
                        Assistentes virtuais preparados para otimizar suas
                        vendas, suporte e atendimento. Sempre disponíveis.
                        Sempre precisos. Sempre eficientes.
                      </p>
                    </div>

                    <div
                      className="banner-btn"
                      data-aos="fade-up"
                      data-aos-delay="300"
                      data-aos-duration="1200"
                    >
                      <a
                        href="https://api.whatsapp.com/send/?phone=5511915124716&text=Olá,%0A%0AGostaria%20de%20solicitar%20um%20orçamento&type=phone_number&app_absent=0"
                        className="default-btn-one"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Comece Agora
                        <span></span>
                      </a>

                      <a className="default-btn" href="#templates" rel="noreferrer">
                        Nossos Agentes
                        <span></span>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="banner-image">
                    {/* Shape images */}
                    <img
                      src="/images/home-two/home-two-shape1.png"
                      alt=""
                      width={200}
                      height={200}
                      data-aos="fade-up"
                      data-aos-delay="100"
                      data-aos-duration="1200"
                    />

                    <img
                      src="/images/home-two/home-two-shape2.png"
                      alt=""
                      width={190}
                      height={200}
                      data-aos="fade-left"
                      data-aos-delay="100"
                      data-aos-duration="1200"
                    />

                    <img
                      src="/images/home-two/home-two-shape3.png"
                      alt=""
                      width={200}
                      height={180}
                      data-aos="fade-right"
                      data-aos-delay="100"
                      data-aos-duration="1200"
                    />

                    <img
                      src="/images/home-two/home-two-shape4.png"
                      alt=""
                      width={210}
                      height={215}
                      data-aos="fade-up"
                      data-aos-delay="100"
                      data-aos-duration="1200"
                    />

                    <img
                      src="/images/home-two/home-two-shape5.png"
                      alt=""
                      width={400}
                      height={270}
                      data-aos="zoom-in"
                      data-aos-delay="100"
                      data-aos-duration="1200"
                    />

                    <img
                      src="/images/home-two/main-image2.png"
                      alt=""
                      width={877}
                      height={877}
                      data-aos="zoom-in"
                      data-aos-delay="100"
                      data-aos-duration="1200"
                    />
                  </div>

                  <div className="circle-img">
                    <img
                      src="/images/home-two/home-two-shape6.png"
                      alt=""
                      width={1160}
                      height={1318}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="creative-shape">
          <img
            src="/images/main-banner-shape/home-shape-2.png"
            alt=""
            width={1920}
            height={1070}
          />
        </div>
        <div className="shape-dot">
          <img
            src="/images/main-banner-shape/main-banner-dot.png"
            alt=""
            width={1150}
            height={1015}
          />
        </div>
      </div>    
    </>
  );
}
