import { Card, CardContent } from "@mui/material";

export default function HowToUse() {
  const detailsHowToUse = [
    {
      step: "1º PASSO",
      subTitle: "1. Defina o perfil",
      text: "Descreva o objetivo do agente IA, como faria em uma contratação, defina tom de voz, e instruções gerais do perfil.",
    },
    {
      step: "2º PASSO",
      subTitle: "2. Ensine seu conteúdo",
      text: "Nos envie documentos, site com dados sobre seus produtos/serviços, colocando perguntas e respostas para treinar seu agente IA.",
    },
    {
      step: "3º PASSO",
      subTitle: "3. Desenhe os processos",
      text: "Defina os fluxos de ações, o que ele precisa fazer e como. Integre as ferramentas necessárias para que ele possa executar as tarefas.",
    },
    {
      step: "4º PASSO",
      subTitle: "4. Melhoria contínua",
      text: "Acompanhe o desempenho do seu agente IA, faça ajustes e melhorias para que ele se torne cada vez mais eficiente.",
    },
  ];

  return (
    <>
      <div id="how-to-use" className="how-to-use-section ptb-100">
        <div className="container">
          <div>
            <div className="section-title">
              <div
                className="software-content mb-4"
                style={{ width: "80%", margin: "0 auto" }}
              >
                <span>COMO USAR</span>
                <h2 className="how-to-use-content">
                  Monte seu <span>agente IA</span> em poucas etapas
                </h2>
              </div>
            </div>

            <div>
              <div className="row mt-5 justify-content-center">
                {detailsHowToUse.map((item, index) => (
                  <div
                    key={index}
                    className="col-5 mb-4 d-flex justify-content-center"
                  >
                    <Card
                      className="rounded-3 p-2 m-0"
                      sx={{ border: "2px solid #00b0ee" }}
                    >
                      <CardContent className="flex-column d-flex align-items-center">
                        <div
                          className="d-flex flex-column"
                          style={{ width: "100%" }}
                        >
                          <span>{item.step}</span>
                          <div className="mb-3">
                            <h2>{item.subTitle}</h2>
                          </div>
                          <p>{item.text}</p>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-3 d-flex justify-content-center ">
              <a
                className="default-btn"
                href="https://api.whatsapp.com/send/?phone=5511915124716&text=Olá,%0A%0AGostaria%20de%20solicitar%20um%20orçamento&type=phone_number&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                Começar agora
                <span></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
