export default function WhatsAppButton() {
  return (
    <>
    <div className="whatsapp-button">
        <a href="https://api.whatsapp.com/send/?phone=5511915124716&text=Olá,%0A%0AGostaria%20de%20solicitar%20um%20orçamento&type=phone_number&app_absent=0"
        target="_blank" rel="noreferrer">
        <img src="/images/whatsapp.png" alt="WhatsApp" />
      </a>
    </div>
    </>
  )
}