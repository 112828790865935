import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import HistoryIcon from "@mui/icons-material/History";
import PsychologyIcon from "@mui/icons-material/Psychology";

export default function WhatIsAI() {
  const detailsIA = [
    {
      subTitle: "Interage como um humano",
      text: "Melhore a experiência do seu cliente com um atendimento natural e humanizado, sempre disponível",
      icon: <PersonOutlineIcon sx={{ fontSize: 50, color: "#00b0ee" }} />,
    },
    {
      subTitle: "Automatiza tarefas rotineiras",
      text: "Deixe a IA cuidar das tarefas repetitivas e permita que sua equipe se dedique ao que realmente importa.",
      icon: (
        <PrecisionManufacturingIcon sx={{ fontSize: 50, color: "#00b0ee" }} />
      ),
    },
    {
      subTitle: "Realiza busca em tempo real",
      text: "Garanta respostas precisas e rápidas aos clientes, com informações atualizadas sobre sua empresa e produtos.",
      icon: <HistoryIcon sx={{ fontSize: 50, color: "#00b0ee" }} />,
    },
    {
      subTitle: "Decide e resolve problemas",
      text: "Permita que a IA resolva problemas de forma autônoma, aumentando a eficiência.",
      icon: <PsychologyIcon sx={{ fontSize: 50, color: "#00b0ee" }} />,
    },
  ];

  return (
    <>
    <div className="questions-ia-section pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="software-image">
                <img
                  src="/images/ai/bot.webp"
                  alt="bot"
                  width={600}
                  height={356}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="software-content mb-4" style={{ width: "80%" }}>
                <span>AGENTES IA</span>
                <h2>O que é um agente de IA?</h2>
              </div>
              <div className="mb-4">
                <p>
                  <strong>Agentes de IA</strong> são como ter um "ChatGPT"
                  dedicado à sua empresa. Eles compreendem e interagem com os
                  clientes, realizam tarefas e operam 24/7 para o seu negócio.
                </p>
              </div>

              <div className="row mt-5">
                {detailsIA.map((item, index) => (
                  <div
                    key={index}
                    className="col-md-6 mb-4 d-flex justify-content-center"
                  >
                    <div
                      className="d-flex flex-column"
                      style={{ width: "70%" }}
                    >
                      <div className="icon mb-3">{item.icon}</div>

                      <div className="mb-3">
                        <h5>{item.subTitle}</h5>
                      </div>
                      <p>{item.text}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-3 d-flex justify-content-center ">
                <a
                  className="default-btn"
                  href="https://api.whatsapp.com/send/?phone=5511915124716&text=Olá,%0A%0AGostaria%20de%20solicitar%20um%20orçamento&type=phone_number&app_absent=0"
                  target="_blank" rel="noreferrer"
                >
                  Criar agente IA
                  <span></span>
                </a>              
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}