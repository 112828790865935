import { Card, CardContent } from "@mui/material";
import TimelineTwoToneIcon from "@mui/icons-material/TimelineTwoTone";
import BarChartIcon from "@mui/icons-material/BarChart";
import LightbulbIcon from "@mui/icons-material/Lightbulb";

export default function Beneficts() {
  const benefictsList = [
    {
      icon: <TimelineTwoToneIcon sx={{ fontSize: 70, color: "#00b0ee" }} />,
      title: (
        <>
          Empresas que desejam ter um <span>atendimento</span> eficiente e
          robusto <span>automatizado</span>
        </>
      ),
    },
    {
      icon: <BarChartIcon sx={{ fontSize: 70, color: "#00b0ee" }} />,
      title: (
        <>
          Profissionais que pretendem{" "}
          <span>escalar o atendimento e vendas</span> da empresa com IA
        </>
      ),
    },
    {
      icon: <LightbulbIcon sx={{ fontSize: 70, color: "#00b0ee" }} />,
      title: (
        <>
          Empreendedores que querem <span>transformar ideias</span> em soluções inovadoras com IA
        </>
      ),
    },
  ];

  return (
    <>
      <div className="pt-100">
        <div className="container">
          <div className="section-title">
            <span>BENEFÍCIOS</span>
            <h2>O parceiro ideal para</h2>
          </div>
          <div className="benefict-section row mt-4 justify-content-center">
            {benefictsList.map((item, index) => (
              <div
                key={index}
                className="col-md-4 mb-4 d-flex justify-content-center"
              >
                <Card
                  className="rounded-3 p-2 dotted-background "
                  sx={{ border: "2px solid #00b0ee" }}
                >
                  <CardContent className="flex-column d-flex align-items-center">
                    {item.icon}
                    <h4>{item.title}</h4>
                  </CardContent>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </div>

      
    </>
  );
}
