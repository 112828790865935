import { useLocation } from "react-router-dom";

interface MenuItemProps {
    label: string;
    link: string;
    submenu?: { label: string; link: string; }[];
}

export default function MenuItem({ label, link, submenu }: MenuItemProps) {

    const location = useLocation();
    const pathname = location.pathname;
    const isActive = location.pathname === link;

    if (submenu) {
        return (
            <li className="nav-item" key={label}>
                <a
                    href={link}
                    className="nav-link"
                    onClick={(e) => e.preventDefault()}
                >
                    {label} <i className="fas fa-chevron-down"></i>
                </a>

                <ul className="dropdown-menu">
                    {submenu.map((subItem) => {
                        const isActive = pathname === subItem.link;
                        return (
                            <li className="nav-item" key={subItem.label}>
                                <a
                                    href={subItem.link}
                                    className={`nav-link ${isActive ? "active" : ""}`}
                                >
                                    {subItem.label}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </li>
        );
    }

    return (
        <li className="nav-item" key={label}>
            <a href={link} className={`nav-link ${isActive ? "active" : ""}`}>
                {label}
            </a>
        </li>
    );
}