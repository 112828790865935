export default function Footer() {

  const currentYear = new Date().getFullYear();

  return (
    <>
      {/* Top Footer Section */}
      <footer className="footer-section ptb-100">
        <div className="container d-flex justify-content-center align-items-center">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>Sobre Nós</h3>
                </div>
                <p>Sua visão, nossa expertise. Juntos, vamos construir soluções digitais inovadoras que impulsionam o sucesso do seu negócio.</p>

                <ul className="footer-social">    

                  <li>
                    <a
                      href="https://eazydev.com.br/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fas fa-globe"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/eazydev/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>           

            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>Modelos IA</h3>
                </div>

                <ul className="footer-quick-links">
                  <li>
                    <a href="#lead">Lead IA</a>
                  </li>
                  <li>
                    <a href="#assist">
                      Assistente IA
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>Informações de Contato</h3>
                </div>

                <div className="footer-info-contact">
                  <i className="flaticon-call-answer"></i>
                  <h3>Telefone</h3>
                  <span>+55 (11) 91512-4716</span>
                </div>

                <div className="footer-info-contact">
                  <i className="flaticon-envelope"></i>
                  <h3>Email</h3>
                  <span>contato@eazydev.com.br</span>
                </div>

                <div className="footer-info-contact">
                  <i className="flaticon-maps-and-flags"></i>
                  <h3>Endereço</h3>
                  <span>
                    Panamérica Park <br /> Av. Guido Caloi, 1000 - Jd. São Luís
                    - Bloco 5 - 4º Andar - São Paulo/SP
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>

        <div className="partner-shape-img1">
          <img
            src="/images/shape/partnar-shape-2.png"
            alt=""
            width={277}
            height={492}
          />
        </div>
      </footer>
      {/* End Top Footer Section */}

      {/* Bottom Footer Section */}
      <div className="copyright-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p>
                &copy; {currentYear} Todos os Direitos Reservados por EazyDev.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
